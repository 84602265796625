<template>
  <div class="container-fluid">
    <b-modal
      hide-footer
      centered
      title="Formulario de votación"
      size="sm"
      v-model="showVoteModal"
    >
      <div>
        <b-form-group label="Email del votante:">
          <b-form-input
            placeholder="Email del votante"
            v-model="emailVoter"
          ></b-form-input>
          <p
            class="text-danger text-size-required pt-2"
            v-if="!$v.emailVoter.required"
          >
            Email requerido.
          </p>
          <p
            class="text-danger text-size-required pt-2"
            v-if="!$v.emailVoter.email"
          >
            Formato de email incorrecto.
          </p>
        </b-form-group>
        <b-button
          class="bg-btnBlue w-100"
          :disabled="this.$v.$invalid"
          @click="vote()"
          >Votar</b-button
        >
      </div>
    </b-modal>

    <b-modal
      hide-footer
      centered
      title="Detalles de la entidad"
      size="md"
      v-model="showEntityDetailsModal"
    >
      <div>
        <h5>{{ entity.name }}</h5>
        <hr />
        <p>{{ entity.description }}</p>
      </div>
    </b-modal>

    <b-modal hide-footer size="md" v-model="showCandidateImage">
      <div class="d-flex justify-content-center">
        <img
          :src="candidateModal.img"
          class="w-50 h-60 my-4"
          alt="imagen del candidato"
        />
      </div>
      <template>
        <div class="d-flex justify-content-center">
          <h5>{{ candidateModal.name }}</h5>
        </div>
      </template>
    </b-modal>

    <div class="row m-2">
      <div
        class="row w-100"
        v-if="showBtnInit"
        style="display: flex; justify-content: center; align-items: center; height: 100vh;"
      >
        <div class="col-12">
          <h2 class="col-12 responsive-font-sector-title-voting">
            A continuación seleccione un candidato por cada sector
          </h2>
          <b-button size="lg" pill class="px-5" @click="initVotation"
            >Iniciar</b-button
          >
        </div>
      </div>
      <div
        class="row w-100"
        v-if="showEndInfo"
        style="display: flex; justify-content: center; align-items: center; height: 100vh;"
      >
        <div class="col-12">
          <h2 class="col-12 responsive-font-sector-title-voting">
            Su proceso de votación ha culminado, gracias por participar.
          </h2>
        </div>
      </div>

      <div class="container-fluid py-3 " v-if="showContent">
        <div class="row d-flex justify-content-center">
          <div class="w-100 mt-4" v-if="sectorTitle.name">
            <b-badge variant="info" class="bg-btnRed">
              <h1 class="responsive-font-sector-title">
                <span v-if="currentIndex == 1">Inicie</span>
                <span v-if="currentIndex == 2">Continúe</span>
                <span v-if="currentIndex == 3">Finalize</span>
                votando por el sector {{ sectorTitle.name }}
                <!-- {{ textPrefix }}  -->
                <!-- {{ currentIndex }} -->
              </h1>
            </b-badge>
          </div>
          <div
            class="container"
            v-if="candidates.length == 0 && currentIndex != 0"
            id="outer"
          >
            <div id="inner">
              <b-spinner
                label="Spinning"
                style="width: 3rem; height: 3rem;"
              ></b-spinner>
            </div>
          </div>
          <div v-for="candidate in candidates" :key="candidate.id" v-else>
            <div>
              <div class="col-sm-12 my-3" id="cardContent">
                <div class="card col-sm-12">
                  <div class="card-body">
                    <div
                      id="img"
                      class="mb-3 d-flex justify-content-center w-100"
                    >
                      <div>
                        <!-- <img :src="ImgUrl(candidate.id)" :alt="'Candidato ' + candidate.name + ' no tiene imagen'" class="w-50 h-60 my-4" @error="errorDetails"  @click="showCandidateImg(candidate)"> -->
                        <!-- <img src="../assets/user.png" alt="imagen candidato" class="w-50 h-60 my-4"> -->
                        <img
                          v-if="candidate.images == ''"
                          src="../assets/user.png"
                          alt="imagen candidato"
                          class="w-50 h-60 my-4"
                        />
                        <img
                          v-else
                          :src="candidate.images"
                          :alt="
                            'Candidato ' + candidate.name + ' no tiene imagen'
                          "
                          class="w-100 h-100"
                        />
                      </div>
                    </div>
                    <h5 class="card-title" style="font-weight: bold;">
                      {{ candidate.name }}
                    </h5>
                    <small class="card-text col-sm-6">{{
                      candidate.companyName
                    }}</small>
                    <div class="w-100 mt-3">
                      <strong>Cantidad de votos:</strong>
                      {{ candidate.totalVotes }}
                    </div>
                    <b-button
                      class="bg-btnRed col-sm-12 my-2"
                      @click="showEntityDetails(candidate)"
                    >
                      ¿Por qué votar por mi?
                    </b-button>
                    <b-button
                      class="bg-btnBlue col-sm-12 mb-2"
                      @click="getCandidate(candidate)"
                      >Votar</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getSectors } from "../api/sector/sectorSercvices";
import { getCandidates } from "../api/candidates/candidatesServices";
import { postRecord } from "../api/Record/RecordServices";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Votes",
  data() {
    return {
      textPrefix: "",
      showContent: true,
      showBtnInit: true,
      showEndInfo: false,
      TotalLenght: 0,
      currentIndex: 0,
      SelectSector: 0,
      sectorList: [],
      candidateList: [],
      candidates: [],
      sectorTitle: "",
      visible: true,
      showVoteModal: false,
      showEntityDetailsModal: false,
      showCandidateImage: false,
      emailVoter: null,
      voteData: {
        candidateId: "",
        candidateLabel: "",
        email: "",
        sectorId: "",
      },
      entity: {
        name: "",
        description: "",
      },
      candidateModal: {
        img: "",
        name: "",
      },
    };
  },
  validations: {
    emailVoter: {
      required,
      email,
    },
  },
  created() {
    this.getSectorsRecords();
    this.getCandidates();
    this.TotalLenght = this.sectorList.length - 1;
  },
  methods: {
    async getCandidates() {
      // const response = await getCandidates();
      // console.log(response);
      axios
        .get("http://localhost:5007/api/SysCandidate")
        .then((response) => {
          // console.log("test", resp.data);

          this.candidateList = response.data;
        });
    },
    errorDetails(event) {},
    ImgUrl(id) {
      return `https://digeigvotaciones.s3.amazonaws.com/${id}.jpg`;
    },
    async getSectorsRecords() {
      const response = await getSectors();
      this.sectorList = response.data;
      return response;
    },

    initVotation() {

      if (this.currentIndex == 0) {
        this.textPrefix = "Inicie";
      } else if (this.currentIndex == 1) {
        this.textPrefix = "Continúe ";
      } else {
        this.textPrefix = "Finalize";
      }

      this.showBtnInit = false;

      if (this.currentIndex != this.sectorList.length) {
        let sector = this.sectorList[this.currentIndex];
        axios
          .get("http://localhost:5007/api/SysCandidate")
          .then((response) => {
            this.candidates = response.data.filter(
              (candidate) => candidate.sectorId == sector.id
            );
            const title = this.sectorList.find(
              (sector) => sector.id == sector.id
            );
            this.sectorTitle = title;
          });
        // this.candidates = this.candidateList.filter(
        //   (candidate) => candidate.sectorId == sector.id
        this.currentIndex++;
        // );
      } else {
        this.showEndInfo = true;
        this.showContent = false;
      }
    },

    getCandidatesBySector(id) {
      const title = this.sectorList.find((sector) => sector.id == id);
      this.sectorTitle = title;
      const response = this.candidateList.filter(
        (candidate) => candidate.sectorId == id
      );
      this.candidates = response;

      // axios
      //   .get("http://localhost:5007/api/SysCandidate")
      //   .then((response) => {
      //     this.candidates = response.data.filter(
      //       (candidate) => candidate.sectorId == sector.id
      //     );
      //   });
    },

    getCandidate(candidate) {
      this.showVoteModal = true;
      this.voteData = {
        candidateId: candidate.id,
        candidateLabel: candidate.name,
        email: this.emailVoter,
        sectorId: candidate.sectorId,
      };
    },

    showEntityDetails(candidate) {
      this.showEntityDetailsModal = true;
      this.entity = {
        name: candidate.companyName,
        description: candidate.descriptionCompany,
      };
    },
    showCandidateImg(candidate) {
      this.showCandidateImage = true;
      this.candidateModal.name = candidate.name;
      this.candidateModal.img = this.ImgUrl(candidate.id);
      // this.candidateModal.img = candidate.images;
    },
    vote() {
      let sector = this.sectorList[this.currentIndex];

      this.showVoteModal = false;
      this.voteData.email = this.emailVoter;
      this.$swal
        .fire({
          title: `¿Está seguro de quiere votar por ${this.voteData.candidateLabel}?`,
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#FF0017",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#003876",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await postRecord(this.voteData);
              this.voteData.email = "";
              this.$swal.fire({
                title: "Usted ha votado correctamente!",
                icon: "success",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#003876",
              });
              this.initVotation();
              return response;
            } catch (error) {
              const errorList = error.response.data.Response.errors;

              errorList.map((error) => {
                var errorMsg = error.errorMessage.trim();
                if (
                  errorMsg == "EL votante ya realizo su voto para este sector."
                ) {
                  if (sector == undefined) {
                    this.initVotation();
                  } else {
                    this.getCandidatesBySector(sector.id);
                    this.currentIndex++;
                  }
                }
              });

              let errorMsg = "";
              errorList.map((error) => {
                {
                  errorMsg = error.errorMessage.trim();
                }
              });

              this.$swal.fire({
                icon: "info",
                title: "Información",
                html: `<ul>${errorList.map(
                  (error) =>
                    "<li>" +
                    error.errorMessage +
                    ` ${
                      errorMsg ==
                      "EL votante ya realizo su voto para este sector."
                        ? ""
                        : " <br> <li> Comuníquese con el departamento de transparencia para el registro del participante. <br><br> <strong> Teléfono:  <a href='https://wa.me/18295203529' target='_blank'> 829-520-3529 </a> o a los correos elizabet.diaz@digeig.gob.do, marlene.reynoso@digeig.gob.do   </strong> </li>"
                    }` +
                    "</li>"
                )}</ul>`,
                text: "Comuniquese con el departamento de transparencia",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#003876",
              });
            }
          } else {
            return;
          }
        });
    },
  },
};
</script>

<style>
#outer {
  width: 100%;
  height: 100vh;
  display: flex;
}

#inner {
  margin: auto;
}
.bg-btnBlue {
  background-color: #203c73 !important;
  border-color: #203c73 !important;
}

.bg-btnRed {
  background-color: #ee2a24 !important;
  border-color: #ee2a24 !important;
}

#cardContent {
  width: 500px;
}

#img {
  height: 200px;
  width: 200px;
  background-size: 100%;
  background-repeat: no-repeat;
}
@media (min-width: 320px) and (max-width: 480px) {
  #cardContent {
    width: 350px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  #cardContent {
    width: 500px;
  }
}

@media (min-width: 1200px) {
  .responsive-font-sector-title {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .responsive-font-sector-title-voting {
    font-size: 40px;
  }
}

@media (max-width: 1199.98px) {
  .responsive-font-sector-title {
    font-size: 15px;
    margin-bottom: 0px;
  }
  .responsive-font-sector-title-voting {
    font-size: 25px;
  }
}
</style>
