<template>
  <div class="d-print-none">
    <div class="container-fluid bg-heading">
        <div class="row justify-content-between pt-3">
          <div class="col-sm-2 col-md-2 col-lg-3">
            <span id="headerTitle" class="text-white">
              <img
                id="imgHeader"
                  src="../assets/logofavicon.svg"
                  alt="institution logo"
                  style="width: 40px;"
                /> DIGEIG - Votaciones
            </span>
          </div>
          <div class="col-sm-2 col-md-2 col-lg-2">
            <p id="headerTitle">
              <b-button to="/Votaciones" class="mx-2">  <b-icon icon="house" aria-hidden="true" id="icon"></b-icon></b-button> 
            </p>
          </div>
        </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data(){
    return {

    }
  },
};
</script>

<style>
  @media (min-width: 320px) and (max-width: 480px) {
    #headerTitle {
      font-size: 15px;
      /* margin-bottom: 0; */
      padding-top: 10px;
    }

    #icon {
      margin-top: 10px;
    }

    #imgHeader {
      margin-bottom: 4px;
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    #headerTitle {
      padding-bottom: -5px;
    }
  }

.bg-heading {
  background-color: #203c73 !important;
}
</style>
