import axios from 'axios';
//import * as names from '../names';
var names ={
    API_ENDPOINT_RECORD :"https://localhost:5001/api/SysVotingRecord"
}
const getRecords = () => {
    let url = `${names.API_ENDPOINT_RECORD}`; 
    return new Promise((resolve, reject) => {
        axios.get(url)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error)
        })
    });
}

const getRecordById = (RecordId) => {
    let url = `${names.API_ENDPOINT_RECORD}/${RecordId}`;
    return new Promise((resolve, reject) => {
        axios.get(url)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

const postRecord = (Record) => {
    let url = `${names.API_ENDPOINT_RECORD}`;
    return new Promise((resolve, reject) => {
        axios.post(url, Record)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error)
        })
    });
}

const putRecord = (Record) => {
    let url = `${names.API_ENDPOINT_RECORD}`;
    return new Promise((resolve, reject) => {
        axios.put(url, Record)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

const deleteRecord = (RecordId, userEmail) => {
    let url = `${names.API_ENDPOINT_RECORD}/${RecordId}/${userEmail}`;
    axios.delete(url, {headers: {
        "Content-Type": "application/json"}
        })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
}

export {
    getRecords, 
    getRecordById, 
    postRecord, 
    putRecord, 
    deleteRecord, 
};