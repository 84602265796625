<template>
    <div class="container">
        <div class="row align-items-center" style="height:410px;">
            <div class="col-lg-12 col-sm-6">
                <h2>Gracias por confirmar su voto</h2>
            </div>
        </div>
    </div>
</template>

<script>
import { postCodeVoting } from "../api/RecordResults/RecordResults";
export default {
    created() {
        this.postCodeVoting();
    },
    methods: {
        async postCodeVoting(){
            const code = this.$route.query.Code;
            console.log('code',code);
            const response = await postCodeVoting(code);
            console.log(response);
            return response;
        }
    }
}
</script>

<style>

</style>