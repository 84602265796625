<template>
  <div style="container-fluid">
    <div class="my-4">
      <h2 class="my-4">Gráfico de las votaciones</h2>
      <div class="container">
        <div class="row justify-content-center my-2">
          <div class="col-sm-12 col-md-2 px-1">
            <b-button
              class="btn btn-primary d-print-none w-100 buttonsVoting"
              variant="primary"
              to="/Participantes"
            >
              <b-icon
                icon="bezier2"
                class="mr-2"
                aria-hidden="true"
                id="icon"
              ></b-icon
              >Participantes</b-button
            >
          </div>
          <div class="col-sm-12 col-md-3 px-1">
            <b-button
              class="btn btn-primary d-print-none w-100 buttonsVoting"
              variant="primary"
              to="/CandidatosAdm"
            >
              <b-icon
                icon="bezier2"
                class="mr-2"
                aria-hidden="true"
                id="icon"
              ></b-icon
              >Candidatos</b-button
            >
          </div>
          <div class="col-sm-12 col-md-2 px-1">
            <b-button
              class="btn btn-primary d-print-none w-100 buttonsVoting"
              variant="primary"
              @click="print()"
            >
              <b-icon
                icon="printer"
                class="mr-2"
                aria-hidden="true"
                id="icon"
              ></b-icon
              >Imprimir</b-button
            >
          </div>
          <div class="col-sm-12 col-md-2 px-1">
            <download-excel
              :data="json_data"
              class="btn btn-primary d-print-none w-100 buttonsVoting"
              variant="primary"
            >
              <b-icon
                icon="file-earmark-excel"
                class="mr-2"
                aria-hidden="true"
                id="icon"
              ></b-icon>
              Exportar
            </download-excel>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div v-for="sector in sectorList" :key="sector.id" class="container">
      <b-badge class="container">
        <div class="row">
          <div class="col-sm-12">
            <h4>{{ sector.name }}</h4>
          </div>
        </div>
      </b-badge>
      <div class="container" v-if="sector.name == 'ONG'">
        <div class="row justify-content-center m-4">
          <div class="col-lg-4 col-sm-12">
            <pie-chart :data="dataOng"></pie-chart>
          </div>
          <div class="col-lg-4 col-sm-12">
            <column-chart :data="dataOng"></column-chart>
          </div>
        </div>
      </div>
      <div class="container" v-if="sector.name == 'Academias'">
        <div class="row justify-content-center m-4">
          <div class="col-lg-4 col-sm-12">
            <pie-chart :data="dataAcademies"></pie-chart>
          </div>
          <div class="col-lg-4 col-sm-12">
            <column-chart :data="dataAcademies"></column-chart>
          </div>
        </div>
      </div>
      <div class="container" v-if="sector.name == 'Empresarial'">
        <div class="row justify-content-center m-4">
          <div class="col-lg-4 col-sm-12">
            <pie-chart :data="dataBusiness"></pie-chart>
          </div>
          <div class="col-lg-4 col-sm-12">
            <column-chart :data="dataBusiness"></column-chart>
          </div>
        </div>
      </div>
      <div v-else></div>
    </div>
  </div>
</template>

<script>
import { GetVotingResults } from "../api/RecordResults/RecordResults";
import { getSectors } from "../api/sector/sectorSercvices";
export default {
  name: "Dashboard",
  data() {
    return {
      votingResult: [],
      sectorList: [],
      candidateList: [],
      dataOng: [],
      dataAcademies: [],
      dataBusiness: [],
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  created() {
    this.getVotingResults();
    this.getSectors();
  },
  methods: {
    addKeyValue(obj, key, data) {
      obj[key] = data;
    },

    async getSectors() {
      const response = await getSectors();
      this.sectorList = response.data;
    },

    async getVotingResults() {
      const candidates = [];
      const candidatesOng = [];
      const candidatesAcademies = [];
      const candidatesBusiness = [];

      const response = await GetVotingResults();
      response.data.map((voting) => {
        const obj = {
          candidate: voting.candaditeName,
          totalVotes: voting.totalVotes,
          sectorId: voting.sectorId,
          sectorName: voting.sectorName,
        };

        const objExcel = {
          Candidato: voting.candaditeName,
          totalVotos: voting.totalVotes,
          Sector: voting.sectorName,
        };
        candidates.push(obj);
        this.json_data.push(objExcel);
      });

      candidates.map((candidate) => {
        if (candidate.sectorName == "ONG") {
          candidatesOng.push(candidate);
        }
        if (candidate.sectorName == "Academias") {
          candidatesAcademies.push(candidate);
        }
        if (candidate.sectorName == "Empresarial") {
          candidatesBusiness.push(candidate);
        }
      });

      const arrOngCandidatesChart = [];
      const arrAcademiesCandidatesChart = [];
      const arrBusinessCandidatesChart = [];

      candidatesOng.map((candidate) => {
        const candidateObj = {
          Name: candidate.candidate,
          Total: candidate.totalVotes,
        };
        const entries = Object.values(candidateObj);
        arrOngCandidatesChart.push(entries);
      });

      candidatesAcademies.map((candidate) => {
        const candidateObj = {
          Name: candidate.candidate,
          Total: candidate.totalVotes,
        };
        const entries = Object.values(candidateObj);
        arrAcademiesCandidatesChart.push(entries);
      });

      candidatesBusiness.map((candidate) => {
        const candidateObj = {
          Name: candidate.candidate,
          Total: candidate.totalVotes,
        };
        const entries = Object.values(candidateObj);
        arrBusinessCandidatesChart.push(entries);
      });

      this.dataOng = arrOngCandidatesChart;
      this.dataAcademies = arrAcademiesCandidatesChart;
      this.dataBusiness = arrBusinessCandidatesChart;
      return response;
    },

    print() {
      window.print();
    },
  },
};
</script>

<style>
@media (min-width: 1200px) {
  .responsive-font-sector-title-voting {
    font-size: 40px;
  }
}

@media (max-width: 1199.98px) {
  .responsive-font-sector-title-voting {
    font-size: 25px;
  }

  .buttonsVoting {
    margin: 2px;
  }
}
</style>
