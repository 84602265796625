import axios from 'axios';
import * as names from '../names';

const getCandidates = () => {
    let url = `${names.API_ENDPOINT_CANDIDATE}`;

    return new Promise((resolve, reject) => {
        axios.get(url)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error)
        })
    });
}

const getCandidateById = (candidateId) => {
    let url = `${names.API_ENDPOINT_CANDIDATE}/${candidateId}`;
    return new Promise((resolve, reject) => {
        axios.get(url)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

const postCandidate = (candidate) => {
    let url = `${names.API_ENDPOINT_CANDIDATE}`;
    return new Promise((resolve, reject) => {
        axios.post(url, candidate)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error)
        })
    });
}

const postCandidateImg = (candidate) => {
    let url = 'http://3.229.254.87:8085/api/Storage/AddFile'
    return new Promise((resolve, reject) => {
        axios.post(url, candidate)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error)
        })
    });
}

const putCandidate = (candidate) => {
    let url = `${names.API_ENDPOINT_CANDIDATE}`;
    return new Promise((resolve, reject) => {
        axios.put(url, candidate)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

const deleteCandidate = (candidateId, userEmail) => {
    let url = `${names.API_ENDPOINT_CANDIDATE}/${candidateId}/${userEmail}`;
    axios.delete(url, {headers: {
        "Content-Type": "application/json"}
        })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
}

export {
    getCandidates, 
    getCandidateById, 
    postCandidate, 
    postCandidateImg,
    putCandidate, 
    deleteCandidate, 
};