<template>
  <div class="container">
    <div
      class="row justify-content-center align-items-center m-5"
      style="height: 450px"
    >
      <b-form
        class="col-sm-12 col-md-6 border p-5 border border-2 rounded shadow"
        @keyup.enter="login()"
      >
        <div class="col-12 my-4">
          <img
            src="../assets/logofavicon.svg"
            alt="Escudo de la República Dominicana"
            style="width: 100px"
            class="border-start"
          />
        </div>
        <div class="col-12 col-sm-12">
          <b-form-group label="Usuario">
            <b-form-input
              v-model="userCredentials.Username"
              :state="null"
              trim
            ></b-form-input>
            <div class="row mx-1">
              <p
                class="text-danger text-size-required m-0"
                v-if="$v.userCredentials.Username.$error"
              >
                Usuario requerido.
              </p>
            </div>
          </b-form-group>
        </div>
        <div class="col-12 col-sm-12">
          <b-form-group label="Contraseña">
            <b-form-input
              v-model="userCredentials.Password"
              type="password"
              :state="null"
              trim
            ></b-form-input>
            <div class="row mx-1">
              <p
                class="text-danger text-size-required m-0"
                v-if="$v.userCredentials.Password.$error"
              >
                Contraseña requerido.
              </p>
            </div>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-button class="w-100" @click="login()">Ingresar</b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "Login",
  data() {
    return {
      userCredentials: [
        {
          Username: "berenice.barinas@digeig.gob.do",
          Password: "barinas",
        },
        {
          Username: "elizabet.diaz@digeig.gob.do",
          Password: "diaz",
        },
        {
          Username: "admin@digeig.gob.do",
          Password: "admin",
        },
        {
          Username: "isael.valdez@digeig.gob.do",
          Password: "valdez",
        },
        {
          Username: "paola.cabrera@digeig.gob.do",
          Password: "cabrera",
        },
        {
          Username: "gady.suazo@digeig.gob.do",
          Password: "suazo",
        },
        {
          Username: "maria.delossantos@digeig.gob.do",
          Password: "delossantos",
        },
        {
          Username: "marlene.reynoso@digeig.gob.do",
          Password: "reynoso",
        },
      ],
    };
  },
  validations: {
    userCredentials: {
      Username: {
        required,
      },
      Password: {
        required,
      },
    },
  },
  methods: {
    login() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal.fire({
          icon: "error",
          title: "ERROR",
          html: `<ul>Por favor complete el formulario correctamente.</ul>`,
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#003876",
        });
      } else {
        var result = this.userCredentials.filter((user) => {
          if (
            user.Username == this.userCredentials.Username &&
            user.Password == this.userCredentials.Password
          ) {
            return user;
          }
        });
        if (result.length >= 1) {
          this.$router.push({ name: "Participant" });
        } else {
          this.$swal.fire({
            icon: "error",
            title: "ERROR",
            html: `<ul>Credenciales incorrectas.</ul>`,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#003876",
          });
        }
      }
    },
  },
};
</script>

<style></style>
