<template>
  <div class="container-md">
    <b-card-group deck>
      <div class="container my-4 bg-primary text-white">
        <h3 class="m-3">Vista Previa</h3>
      </div>
      <div class="row w-100 m-4">
        <div
          class="col-sm-12 col-md-4 col-lg-4 my-4"
          v-for="candidate in candidateList"
          :key="candidate.id"
        >
          <b-card
            :title="candidate.name"
            header-tag="header"
            footer-tag="footer"
            class="p-4"
          >
            <div v-if="candidate.images">
              <b-modal :id="candidate.name" title="Imagen">
                <b-img
                  :src="candidate.images"
                  fluid-grow
                  alt="Fluid image"
                ></b-img>
              </b-modal>
            </div>
            <div class="my-4">
              <b-img
                :src="candidate.images"
                fluid-grow
                alt="Fluid image"
                class="w-50 h-50"
              >
              </b-img>
            </div>
          </b-card>
        </div>
      </div>
    </b-card-group>
  </div>
</template>

<script>
import {
  getCandidates,
  postCandidateImg,
  putCandidate,
} from "../api/candidates/candidatesServices";

import axios from "axios";

export default {
  name: "Candidates",

  data() {
    return {
      Buckets: "digeigvotaciones",
      uploadURL: "http://3.229.254.87:8085/api/Storage/upload",
      projectName: "",
      file: "",
      candidateList: [],
      myFiles: ["cat.jpeg"],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" },
      },
    };
  },
  created() {
    this.getCandidates();
  },
  methods: {
    ImgUrl(id) {
      return `https://digeigvotaciones.s3.amazonaws.com/${id}.jpg`;
    },
    getFile(event) {
      this.file = event.target.files[0];
    },
    async submitForm(event, id) {
      event.preventDefault();
      let formData = new FormData();
      formData.append("file", this.file);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post(
          this.uploadURL + `?name=${id}&Buckets=${this.Buckets}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((response) => {
          this.$swal.fire({
            icon: "success",
            title: "Imagen guardada exitosamente",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#003876",
          });
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Ha ocurrido un inconveniente",
            html: `<ul>Intente </ul>`,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#003876",
          });
        });
    },
    async getCandidates() {
      const response = await getCandidates();
      this.candidateList = response.data;
    },
  },
};
</script>

<style></style>
