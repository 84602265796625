import axios from 'axios';
import * as names from '../names';


// API_ENDPOINT_SECTOR

const getSectors = () => {
    let url = `${names.API_ENDPOINT_SECTOR}`; 
    console.log(url)
    return new Promise((resolve, reject) => {
        axios.get(url)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error)
        })
    });
}

const getSectorById = (SectorId) => {
    let url = `${names.API_ENDPOINT_SECTOR}/${SectorId}`;
    return new Promise((resolve, reject) => {
        axios.get(url)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

const postSector = (Sector) => {
    let url = `${names.API_ENDPOINT_SECTOR}`;
    return new Promise((resolve, reject) => {
        axios.post(url, Sector)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error)
        })
    });
}

const putSector = (Sector) => {
    let url = `${names.API_ENDPOINT_SECTOR}`;
    return new Promise((resolve, reject) => {
        axios.put(url, Sector)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

const deleteSector = (SectorId, userEmail) => {
    let url = `${names.API_ENDPOINT_SECTOR}/${SectorId}/${userEmail}`;
    axios.delete(url, {headers: {
        "Content-Type": "application/json"}
        })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
}

export {
    getSectors, 
    getSectorById, 
    postSector, 
    putSector, 
    deleteSector, 
};