import Vue from 'vue'
import VueRouter from 'vue-router'
import Participant from '../views/Participant.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Dashboard from '../views/Dashboard.vue'
import Confirmation from '../views/Confirmation.vue'
import Votes from '../views/Votes.vue'
import Login from '../views/Login.vue'
import Candidates from '../views/Candidates.vue'
import CandidatesAdministration from '../views/CandidatesAdministration.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Participantes',
    name: 'Participant',
    component: Participant
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/Votaciones',
    name: 'Votes',
    component: Votes
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Candidatos',
    name: 'Candidates',
    component: Candidates
  },
  {
    path: '/CandidatosAdm',
    name: 'CandidatesAdministration',
    component: CandidatesAdministration
  },
  {
    path: '/Votaciones/Confirmation',
    name: 'Confirmation',
    component: Confirmation
  },
  // {
  //   path: '*',
  //   name: 'Votes',
  //   component: Votes
  // },
  {
    path: '*',
    redirect: '/Login'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
