<template>
  <div class="d-flex p-5 h-100">
    <div>
      <b-modal
        hide-footer
        id="modal-center"
        size="lg"
        v-model="showParticipantFormEdit"
        centered
        title="Formulario de para editar Participante"
      >
        <b-form>
          <div class="row">
            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Email:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="Participant.Email"
                ></b-form-input>
                <p
                  class="text-danger text-size-required pt-2"
                  v-if="!$v.Participant.Email.required"
                >
                  Email requerido.
                </p>
                <p
                  class="text-danger text-size-required pt-2"
                  v-if="!$v.Participant.Email.email"
                >
                  Formato de email incorrecto.
                </p>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Entidad:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="Participant.CompanyName"
                ></b-form-input>
                <p
                  class="text-danger text-size-required pt-2"
                  v-if="!$v.Participant.CompanyName.required"
                >
                  Entidad requerido.
                </p>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Nombre:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="Participant.PersonContact"
                ></b-form-input>
                <p
                  class="text-danger text-size-required pt-2"
                  v-if="!$v.Participant.PersonContact.required"
                >
                  Nombre requerido.
                </p>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Teléfono principal:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="Participant.MainPhone"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group label="Género">
                <b-form-radio
                  v-model="Participant.IsMale"
                  name="some-radios"
                  :value="true"
                  >Masculino</b-form-radio
                >
                <b-form-radio
                  v-model="Participant.IsMale"
                  name="some-radios"
                  :value="false"
                  >Femenino</b-form-radio
                >
              </b-form-group>
              <p
                class="text-danger text-size-required pt-2"
                v-if="!$v.Participant.IsMale.required"
              >
                Género requerido.
              </p>
            </div>

            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Sector:"
                label-for="input-1"
              >
                <b-form-select
                  id="input-1"
                  v-model="Participant.SectorId"
                  :options="options"
                ></b-form-select>
                <p
                  class="text-danger text-size-required pt-2"
                  v-if="!$v.Participant.SectorId.required"
                >
                  Sector requerido.
                </p>
              </b-form-group>
            </div>
          </div>
        </b-form>

        <b-button
          variant="primary"
          size="md"
          class="float-right"
          :disabled="this.$v.$invalid"
          @click="editParticipant()"
        >
          Guardar
        </b-button>
      </b-modal>

      <b-modal
        hide-footer
        id="modal-center"
        size="lg"
        v-model="showParticipantForm"
        centered
        title="Formulario de Participante"
      >
        <b-form>
          <div class="row">
            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Email:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="Participant.Email"
                ></b-form-input>
                <p
                  class="text-danger text-size-required pt-2"
                  v-if="!$v.Participant.Email.required"
                >
                  Email requerido.
                </p>
                <p
                  class="text-danger text-size-required pt-2"
                  v-if="!$v.Participant.Email.email"
                >
                  Formato de email incorrecto.
                </p>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Entidad:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="Participant.CompanyName"
                ></b-form-input>
                <p
                  class="text-danger text-size-required pt-2"
                  v-if="!$v.Participant.CompanyName.required"
                >
                  Entidad requerido.
                </p>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Nombre:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="Participant.PersonContact"
                ></b-form-input>
                <p
                  class="text-danger text-size-required pt-2"
                  v-if="!$v.Participant.PersonContact.required"
                >
                  Nombre requerido.
                </p>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Teléfono principal:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="Participant.MainPhone"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group label="Género">
                <b-form-radio
                  v-model="Participant.IsMale"
                  name="some-radios"
                  :value="true"
                  >Masculino</b-form-radio
                >
                <b-form-radio
                  v-model="Participant.IsMale"
                  name="some-radios"
                  :value="false"
                  >Femenino</b-form-radio
                >
              </b-form-group>
              <p
                class="text-danger text-size-required pt-2"
                v-if="!$v.Participant.IsMale.required"
              >
                Género requerido.
              </p>
            </div>

            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Sector:"
                label-for="input-1"
              >
                <b-form-select
                  id="input-1"
                  v-model="Participant.SectorId"
                  :options="options"
                ></b-form-select>
                <p
                  class="text-danger text-size-required pt-2"
                  v-if="!$v.Participant.SectorId.required"
                >
                  Sector requerido.
                </p>
              </b-form-group>
            </div>
          </div>
        </b-form>

        <b-button
          variant="primary"
          size="md"
          class="float-right"
          :disabled="this.$v.$invalid"
          @click="saveParticipant()"
        >
          Guardar
        </b-button>
      </b-modal>

      <b-modal
        hide-footer
        id="modal-center"
        size="lg"
        v-model="showParticipantDetails"
        centered
        title="Participante"
      >
        <b-form>
          <div class="row">
            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Email:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="Participant.Email"
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Entidad:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="Participant.CompanyName"
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Nombre:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="Participant.PersonContact"
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Teléfono principal:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="Participant.MainPhone"
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group label="Género" disabled>
                <div v-if="Participant.IsMale">
                  <b-form-radio
                    v-model="Participant.IsMale"
                    name="some-radios"
                    :value="true"
                    >Masculino</b-form-radio
                  >
                </div>
                <div v-else>
                  <b-form-radio
                    v-model="Participant.IsMale"
                    name="some-radios"
                    :value="false"
                    >Femenino</b-form-radio
                  >
                </div>
              </b-form-group>
              <p
                class="text-danger text-size-required pt-2"
                v-if="!$v.Participant.IsMale.required"
              >
                Género requerido.
              </p>
            </div>

            <div class="col-6">
              <b-form-group
                id="input-group-1"
                label="Sector:"
                label-for="input-1"
              >
                <b-form-select
                  id="input-1"
                  v-model="Participant.SectorId"
                  :options="options"
                  disabled
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
        </b-form>
      </b-modal>
    </div>
    <div class="container my-5">
      <div class="d-flex">
        <b-button to="/dashboard" class="mx-2">
          <b-icon
            icon="file-bar-graph-fill"
            aria-hidden="true"
            id="icon"
          ></b-icon
        ></b-button>
        <b-button to="/Participantes" class="mx-2">
          <b-icon icon="person-plus" aria-hidden="true" id="icon"></b-icon
        ></b-button>
        <b-button to="/CandidatosAdm" class="mx-2">
          <b-icon
            icon="file-earmark-person"
            aria-hidden="true"
            id="icon"
          ></b-icon
        ></b-button>
        <b-button to="/Candidatos" class="mx-2">
          <b-icon
            icon="file-earmark-person"
            aria-hidden="true"
            id="icon"
          ></b-icon
        ></b-button>
      </div>
      <b-row class="d-flex justify-content w-75 mt-2 ml-1" align-h="between">
        <b-col cols="2" class="pl-0">
          <b-form-group
            label="Por pagina"
            label-align="left"
            label-for="per-page-select"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="6" class="pr-0">
          <b-form-group
            label="Buscar"
            label-align="left"
            label-for="filter-input"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="search"
                type="search"
                placeholder="Participante"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  :disabled="!search"
                  @click="search = ''"
                  variant="primary"
                >
                  Limpiar
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="w-75 mt-4">
        <div
          class="border d-flex align-items-center justify-content-between p-3 bg-white text-dark"
        >
          <div>
            <h4 class="m-0">Participantes</h4>
          </div>
          <div>
            <b-button size="sm" variant="primary" @click="addparticipant()">
              Agregar Participante
              <b-icon icon="plus-circle-fill" class="ml-2"></b-icon>
            </b-button>
          </div>
        </div>
        <b-table
          striped
          hover
          :items="filteredList"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :foot-clone="false"
          empty-text="No hay participantes registrados"
          empty-filtered-text="No hay instituciones que coincidan con su solicitud"
          stacked="md"
          show-empty
          small
          class="mb-0 border w-100"
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(actions)="row">
            <b-button
              variant="primary"
              size="sm"
              class="mx-2"
              @click="modalDetails(row.item.id)"
            >
              <b-icon
                icon="eye-fill"
                aria-hidden="true"
                class="text-white"
              ></b-icon>
            </b-button>

            <b-button
              variant="warning"
              size="sm"
              class="mx-2"
              @click="modalFormEdit(row.item.id)"
            >
              <b-icon
                icon="pencil-fill"
                aria-hidden="true"
                class="text-white"
              ></b-icon>
            </b-button>
            <b-button
              variant="danger"
              size="sm"
              @click="showAlertDeleteRecord(row.item.id, userEmail)"
            >
              <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
            </b-button>
          </template>
        </b-table>
        <div class="w-100 d-flex justify-content-end">
          <b-col sm="7" md="3" class="pr-0 mt-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getParticipants,
  getParticipantById,
  postParticipant,
  putParticipant,
  deleteParticipant,
} from "../api/participants/particantsServices";
import { getSectors } from "../api/sector/sectorSercvices";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "Participants",
  data() {
    return {
      showParticipantDetails: false,
      showParticipantForm: false,
      showParticipantFormEdit: false,
      ParticipantId: null,
      search: "",
      participantRecords: [],
      userEmail: "admin@gmail.com",
      options: [],
      Participant: {
        Id: "",
        CompanyName: "",
        Email: "",
        MainPhone: "",
        SecondaryPhone: "",
        PersonContact: "",
        EmailPersonContact: "",
        MainPhonePersonContact: "",
        SecondaryPhonePersonContact: "",
        AnotherPhonePersonContact: "",
        IsMale: true,
        SectorId: "",
        IsActive: 1,
      },
      fields: [
        "index",
        {
          key: "email",
          label: "Email",
          sortable: true,
          sortDirection: "desc",
          class: "text-left px-5",
        },
        { key: "actions", label: "Acciones" },
        {
          formatter: (value, key, item) => {
            return value ? "Yes" : "No";
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Mostrar todos" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
    };
  },
  validations: {
    Participant: {
      Email: {
        email,
        required,
      },
      CompanyName: {
        required,
      },
      PersonContact: {
        required,
      },
      IsMale: {
        required,
      },
      SectorId: {
        required,
      },
    },
  },
  created() {
    this.getParticipantsRecords();
    this.getSectors();
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filteredList() {
      return this.participantRecords.filter((record) => {
        return record.email.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    addparticipant() {
      this.showParticipantForm = true;
      this.clearParticipantForms();
    },

    async getParticipantsRecords() {
      const response = await getParticipants();
      this.participantRecords = response.data;
      return response;
    },

    async getSectors() {
      const response = await getSectors();
      this.options = response.data.map((sector) => {
        return { value: sector.id, text: sector.name };
      });
      return response;
    },

    async getParticipantById(id) {
      const response = await getParticipantById(id);
      const ParticipantData = response.data;
      console.log(response.data);
      this.Participant = {
        Id: ParticipantData.id,
        CompanyName: ParticipantData.companyName,
        Email: ParticipantData.email,
        MainPhone: ParticipantData.mainPhone,
        SecondaryPhone: ParticipantData.secondaryPhone,
        PersonContact: ParticipantData.personContact,
        EmailPersonContact: ParticipantData.emailPersonContact,
        MainPhonePersonContact: ParticipantData.mainPhonePersonContact,
        SecondaryPhonePersonContact:
          ParticipantData.secondaryPhonePersonContact,
        AnotherPhonePersonContact: ParticipantData.anotherPhonePersonContact,
        IsMale: ParticipantData.isMale,
        SectorId: ParticipantData.sectorId,
        IsActive: 1,
      };
      console.log(response.data);
      return response;
    },

    async postParticipant(Participant) {
      try {
        const response = await postParticipant(Participant);
        this.$swal.fire({
          icon: "success",
          title: "Registro guardado exitosamente",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#003876",
        });
        this.getParticipantsRecords();
        this.clearParticipantForms();
        return response;
      } catch (error) {
        const errorList = error.response.data.Response.errors;
        this.$swal.fire({
          icon: "error",
          title: "Ha ocurrido un inconveniente",
          html: `<ul>${errorList.map(
            (error) => "<li>" + error.errorMessage + "</li>"
          )}</ul>`,
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#003876",
        });
      }
      return response;
    },

    async putParticipant(Participant) {
      try {
        console.log(Participant);
        const response = await putParticipant(Participant);
        this.$swal.fire({
          icon: "success",
          title: "Registro editado exitosamente",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#003876",
        });
        this.getParticipantsRecords();
        this.clearParticipantForms();
        return response;
      } catch (error) {
        const errorList = error.response.data.Response.errors;
        this.$swal.fire({
          icon: "error",
          title: "Ha ocurrido un inconveniente",
          html: `<ul>${errorList.map(
            (error) => "<li>" + error.errorMessage + "</li>"
          )}</ul>`,
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#003876",
        });
      }
      return response;
    },

    clearParticipantForms() {
      this.Participant = {
        Email: "",
        MainPhone: "",
        SecondaryPhone: "",
        PersonContact: "",
        EmailPersonContact: "",
        MainPhonePersonContact: "",
        SecondaryPhonePersonContact: "",
        AnotherPhonePersonContact: "",
        IsMale: "",
        SectorId: "",
      };
    },

    modalDetails(id) {
      this.getParticipantById(id);
      this.showParticipantDetails = true;
    },

    modalFormEdit(id) {
      this.getParticipantById(id);
      this.showParticipantFormEdit = true;
    },

    saveParticipant() {
      this.postParticipant(this.Participant);
      this.getParticipantsRecords();
    },

    editParticipant() {
      this.putParticipant(this.Participant);
      this.getParticipantsRecords();
    },

    showAlertDeleteRecord(ParticipantId, userEmail) {
      {
        this.$swal
          .fire({
            title: "Estas seguro de que quieres eliminar el registro?",
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#FF0017",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#003876",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              try {
                const response = await deleteParticipant(
                  ParticipantId,
                  userEmail
                );
                this.$swal.fire({
                  title: "El registro ha sido eliminado correctamente!",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#003876",
                });
                this.getParticipantsRecords();
                return response;
              } catch (error) {
                const errorList = error.response.data.Response.errors;
                this.$swal.fire({
                  icon: "error",
                  title: "Ha ocurrido un inconveniente",
                  html: `<ul>${errorList.map(
                    (error) => "<li>" + error.errorMessage + "</li>"
                  )}</ul>`,
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#003876",
                });
              }
            } else {
              return;
            }
          });
      }
    },
  },
};
</script>

<style>
.text-size-required {
  font-size: 12px;
}
</style>
