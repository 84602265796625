import axios from 'axios';
//import * as names from '../names';
var names ={
    API_ENDPOINT_RECORD_RESULTS :"https://localhost:5001/api/SysVotingRecord/GetVotingResults",
    API_ENDPOINT_VOTING_CODE :"https://localhost:5001/api/SysVotingRecord/GetVotingConfirmation"
}
const GetVotingResults = () => {
    let url = `${names.API_ENDPOINT_RECORD_RESULTS}`; 
    return new Promise((resolve, reject) => {
        axios.get(url)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error)
        })
    });
}

const postCodeVoting = (code) => {
    console.log('service',code);
    let url = `${names.API_ENDPOINT_VOTING_CODE}`;
    return new Promise((resolve, reject) => {
        axios.post(url, code, {headers: {
            "Content-Type": "application/json"}
            })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error)
        })
    });
}

export {
    GetVotingResults,
    postCodeVoting
}